import React from 'react';

// Import styles
import './styles.scss';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { HEADER_HREF } from 'helpers';
import { DOWNLOAD_ITEMS } from './helpers';

// Import components
import { Downloader, Section, Typography } from 'components/elements';

const Download = () => {
	const { t } = useTranslations();

	return (
		<Section
			id={HEADER_HREF.DOWNLOAD}
			isAutoHeight
			className={`bg-primary`}
		>
			<div
				className={`container mx-auto text-center flex flex-col items-center py-10 px-5 container-wrapper
                    md:py-20`}
			>
				<Typography className={`text-white md:text-2xl`}>
					{t('download.content')}
				</Typography>
				<div
					className={`flex justify-evenly items-center w-full pt-5 xl:pt-10`}
				>
					{DOWNLOAD_ITEMS.map((props) => (
						<Downloader key={props.variant} {...props} />
					))}
				</div>
			</div>
		</Section>
	);
};

export default Download;
