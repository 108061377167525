import React from 'react';
import Layout from '../components/layout/Layout';

import {
	Start,
	Video,
	About,
	Functions,
	Download
} from '../components/sections';

const Index = () => (
	<Layout>
		<Start />
		<Video />
		<About />
		<Functions />
		<Download />
	</Layout>
);

export default Index;
