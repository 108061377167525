import React from 'react';

// Import helpers
import { HEADER_HREF } from 'helpers';
import { MEDIA_QUERY_SIZE } from 'helpers/styles';

// Import components
import { Section } from 'components/elements';

// Import utilities
import { useMediaQuery } from 'components/utilities';

const Video = () => {
	const match = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.md}px)`);

	return (
		<Section id={HEADER_HREF.VIDEO} isAutoHeight={!match}>
			<video
				className="w-full h-full object-cover"
				poster="/video_poster.png"
				controls
			>
				<source src="/nfz_video.mp4" type="video/mp4" />
			</video>
		</Section>
	);
};

export default Video;
