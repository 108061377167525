import { DOWNLOAD_APP_LINKS } from 'helpers';

export const DOWNLOAD_ITEMS = [
	{
		className: `md:px-5`,
		variant: 'apple',
		link: DOWNLOAD_APP_LINKS.APP_STORE
	},
	{
		className: `md:px-5`,
		variant: 'android',
		link: DOWNLOAD_APP_LINKS.GOOGLE_PLAY
	}
];
